<template>
	<mw-dialog
		v-model="dialog"
		small
		submitBtn
		cancelBtn
		@submit="submit"
		:title="$t('assessment.return')"
	>
		<template v-slot:button>
			<v-btn v-if="icon" icon @click="dialog = true">
				<v-icon v-if="icon">mdi-check</v-icon>
			</v-btn>
			<v-list-item v-else @click="dialog = true">
				<v-list-item-title>
					{{ $t("assessment.return") }}
				</v-list-item-title>
			</v-list-item>
		</template>
		<template v-slot:dialog-body>
			<p >{{ $t("messages.before_return") }}</p>
		</template>
	</mw-dialog>
</template>

<style lang="less">
.button {
	cursor: pointer;
}
</style>
<script>
import MwDialog from "@c/ui/MwDialog";

export default {
	name: "Revoke",
	props: {
		id: { type: String }, // the ID of the response,
		icon: { type: Boolean, default: false },
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		response() {
			return this.$store.state.sectionResponses.data[this.id];
		},
        user(){
            return this.$store.getters["auth/id"]
        }
	},
	components: {
		MwDialog,
	},
	methods: {
		submit() {
			const self = this;
			self.$store
				.dispatch("sectionResponses/patch", {
					id: self.id,
					status: "returned",
				})
			self.$store
				.dispatch("sectionResponses/delete", `${self.id}.users.${self.user}`)

		},
	},
};
</script>
